import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import PageHero from '../components/hero/PageHero';
import PostsList from './list-posts-template';

import heroImage from '../images/blog-hero.jpg';

const BlogList = ({ data, pageContext }) => (
  <Layout>
    <SEO
      title="Blog"
      description="New Normal web and mobile development case studies. We are experts in Seattle."
    />
    <PageHero image={heroImage}>Blog</PageHero>
    <PostsList data={data} pageContext={pageContext} prefix="blog" />
  </Layout>
);

export default BlogList;

export const query = graphql`
  query blogMarkdown($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//blog//"}
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        ...MarkdownPostNode
      }
    }
  }
`;
