import React from 'react';
import styled from 'styled-components';
import arrow from '../images/arrow.svg';
import { WithUnderline } from '../styles/link';
import { color } from '../styles/theme';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 40px 15px;
  width: 100%;
  max-width: 950px;
`;

const PaginateLink = styled(WithUnderline)`
  position: relative;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: ${color('turquoise-blue')};
  text-decoration: none;
  
  @media (min-width: 768px) {
    font-size: 24px;
  }
  
  img {
    position: relative;
    top: .125em;
    margin-left: 10px;
    height: 1em;
    width: 1em;
    transition: all .2s ease-in-out;
  }
`;

const Pagination = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/blog/' : `/blog/${(currentPage - 1).toString()}/`;
  const nextPage = `/blog/${(currentPage + 1).toString()}/`;

  return (
    <PaginationWrapper>
      {!isFirst && (
        <PaginateLink to={prevPage}>
          <img
            src={arrow}
            alt=""
            css={{
              marginRight: '10px',
              marginLeft: '0 !important',
              transform: 'rotate(180deg)',
            }}
          />
          <span>Previous page</span>
        </PaginateLink>
      )}
      {!isLast && (
        <PaginateLink to={nextPage}>
          <span>Next page</span>
          <img src={arrow} alt="" />
        </PaginateLink>
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
