import React from 'react';
import ArticlesNav from '../components/articles/ArticlesNav';
import ArticlesList from '../components/articles/ArticlesList';
import ArticlesNavContainer from '../components/articles/ArticlesNavContainer';
import Pagination from '../components/Pagination';

const PostsList = ({ data, pageContext, prefix }) => {
  const { currentPage, numPages, type } = pageContext;

  return (
    <main>
      <ArticlesList data={data.allMarkdownRemark} type={type} />
      <Pagination prefix={prefix} currentPage={currentPage} numPages={numPages} />
      <ArticlesNavContainer>
        <ArticlesNav title="Next Step:" link="/#contact" name="Get in touch" />
      </ArticlesNavContainer>
    </main>
  );
};

export default PostsList;
