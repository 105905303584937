import styled from 'styled-components';
import { themeColor, color } from './theme';
import { WithUnderline } from './link';

const Wrapper = styled.div`
  padding: 95px 15px;
  background-color: ${color('turquoise-blue-200')};
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 920px;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Title = styled.p`
  margin-bottom: 15px;
  font-weight: bold;
  line-height: 1.25;
  text-transform: uppercase;
  color: ${themeColor('primary')};
`;

const Link = styled(WithUnderline)`
  padding-bottom: 5px;
  font-size: 33px;
  line-height: 1.4;
  letter-spacing: -0.8px;
  color: ${color('white')};
  
  @media (min-width: 768px) {
    font-size: 36px;
  }
  
  .blog-navigation & {
    font-size: 22px;
  }
`;

export {
  Wrapper,
  Container,
  Inner,
  Title,
  Link,
};
