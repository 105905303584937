import React from 'react';
import styled, { css } from 'styled-components';
import hex2rgba from 'hex2rgba';

import { Header } from '../Header';
import { MainNav } from '../navigation/MainNav';
import NavHero from '../navigation/NavHero';
import { color, themeColor } from '../../styles/theme';

const Wrapper = styled.div`
  position: relative;
  padding: 30px 15px;
  height: 100vh;
  background-color: ${themeColor('primary')};
  overflow: hidden;
  
  ${(heroImage) => heroImage && css`
    background-image: url(${({ heroImage }) => heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `};
  
  @media (min-width: 768px) {
    padding-top: 28px;
    padding-bottom: 75px;
  }
  
  @media (min-width: 1920px) {
    padding-top: 120px;
    padding-bottom: 120px;
    max-height: 770px;
  }
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      circle at 50% 0,
      ${hex2rgba(color('white'), 0.5)},
      ${hex2rgba(themeColor('primary'), 0.75)}
    );
    background-color: ${hex2rgba(themeColor('primary'), 0.85)};
    mix-blend-mode: color;
  }
`;

const Title = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 700px;
  font-size: 33px;
  line-height: 1.5;
  text-align: center;
  color: ${color('white')};
`;

const PageHero = ({ image, children }) => (
  <Wrapper heroImage={image}>
    <Header>
      <NavHero />
      <MainNav />
    </Header>
    {children && (<Title>{ children }</Title>)}
  </Wrapper>
);

export default PageHero;
