import React from 'react';
import styled, { keyframes } from 'styled-components';
import { themeColor } from '../styles/theme';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1148px;
  
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
`;

const slideDown = keyframes`
  0% {
    top: -100px;
  }
  
  100% {
    top: 0;
  }
`;

const Wrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 20px 15px;
  width: 100%;
  will-change: top;
  
  @media (min-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  &.sticky {
    position: fixed;
    top: -100px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: ${themeColor('primary')};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    animation: ${slideDown} .5s cubic-bezier(0.25, 0.46, 0.45, 0.94) .1s forwards;
  }
`;

class Header extends React.Component {
  state = {
    active: false,
    disableAnimation: this.props.disableAnimation || false, // eslint-disable-line
  };

  componentDidMount() {
    const { disableAnimation } = this.state;

    if (disableAnimation) return;

    window.addEventListener('scroll', this.handleScroll, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { active } = this.state;

    if (nextState.active === active) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  handleScroll = () => {
    if (window.pageYOffset > 160) {
      this.setState({ active: true });
    } else {
      this.setState({ active: false });
    }
  };

  render() {
    const { active } = this.state;
    const { children } = this.props;

    return (
      <Wrapper className={`main-header ${active && 'sticky'}`}>
        <Container>
          { children }
        </Container>
      </Wrapper>
    );
  }
}

export { Header };
